import { gql } from "@apollo/client";

export const GET_TENANT_SERVICES = gql`
  query tenantServices($clientId: Int!, $searchTerm: String) {
    tenantServices(clientId: $clientId, searchTerm: $searchTerm) {
      name
      id
      status
    }
  }
`;

export const GET_CLIENT_NAME = gql`
  query Client($clientId: Int!) {
    client(clientId: $clientId) {
      name
      id
    }
  }
`;

export const GET_CLIENT_MODE = gql`
  query Client($clientId: Int!) {
    client(clientId: $clientId) {
      mode
    }
  }
`;

export const GET_CLIENT_OUTBOUND_FAX = gql`
  query Client($clientId: Int!) {
    client(clientId: $clientId) {
      faxNumber
      id
    }
  }
`;

export const GET_CLIENT_TENANT_GROUPS = gql`
  query Client($clientId: Int!) {
    client(clientId: $clientId) {
      id
      name
      associatedTenantGroup {
        name
        memberClients {
          id
          name
        }
      }
    }
  }
`;

export const GET_TENANT_GROUPS = gql`
  query tenantGroups($clientId: Int!) {
    tenantGroups(clientId: $clientId) {
      createTime
      description
      id
      lastModifiedTime
      memberClients {
        id
      }
      name
    }
  }
`;

export const GET_TENANT_FAX_LINES = gql`
  query getDidsForTenant($clientId: Int!) {
    tenantDids(clientId: $clientId) {
      description
      did
      clientId
    }
  }
`;

export const clientsBatchQuery = (clientIds) => {
  if (clientIds.length <= 0) {
    return GET_CLIENT_NAME;
  }

  const queries = clientIds
    .map(
      (clientId) => `
    client_${clientId}: client(clientId: ${clientId}) {
      id
      name
    }
  `
    )
    .join("\n");

  return gql`
    query GetClientsBatch {
      ${queries}
    }
  `;
};
