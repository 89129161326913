import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import {
  useSearchkit,
  withSearchkit,
  withSearchkitRouting,
  useSearchkitVariables,
} from "@searchkit/client";
import { Pagination, FacetsList } from "@searchkit/elastic-ui";
import "@elastic/eui/dist/eui_theme_light.css";
import {
  Grid,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
  Tooltip,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import { getSpecificDateRange } from "../../utils";
import { OverridableListFacet } from "../CustomFacets/OverridableListFacet";
import { OverridableDateRangeFacet } from "../CustomFacets/OverridableDateRangeFacet";
import { OverridableValueFilter } from "../CustomFilters/OverridableValueFilter";
import { Link } from "react-router-dom";
import { indigo } from "@material-ui/core/colors";
import { PHSelectedFilters } from "../PHSelectedFilters/PHSelectedFilters";
import { useSelector, useDispatch } from "react-redux";
import { saveVariables, persistVariables } from "../../state/ducks/activity";
import { ACTIVITIES_QUERY } from "../../queries/activities";
import PropTypes from "prop-types";
import { ACTIVITY_TYPE_NAME } from "../../constants";
import {
  isValidToDisplay,
  getReferralUrl,
  getDocumentUrl,
  getPatientName,
  getCustomizedSummary,
  isComment,
} from "../../utils";

//For Redirect in case of GraphQL authentication error
const host =
  window.location.hostname === "localhost"
    ? "phealth-dev"
    : window.location.hostname.split(".")[1];

const redirectUrl =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/exchange/"
    : `https://phealth.${host}.net/exchange/`;

function getRelatedReferral(items, clientId) {
  return items.map((item) =>
    item.typeName === "SENTREFERRAL" || item.typeName === "RCVDREFERRAL" ? (
      <Link key={`related-${item?.key}`} to={`${getReferralUrl(item)}`}>
        {item.name || ""}
      </Link>
    ) : item.typeName === "DOCSERVER_DOCUMENT" ? (
      <Link
        key={`related-${item?.key}`}
        to={`${getDocumentUrl(item, clientId)}`}
        target="_blank"
        rel="opener"
      >
        {item.name || ""}
      </Link>
    ) : null
  );
}

const HomeActivityStream = ({ selfId, newForm }) => {
  const dispatch = useDispatch();
  const savedVariables = useSelector(
    (state) => state.activity.savedSearchVariables
  );
  const [useSaved, setUseSaved] = useState(
    useSelector((state) => state.activity.useSaved) || false
  );
  let variables = useSearchkitVariables();
  const api = useSearchkit();
  const [dateSet, setDefaultDate] = useState(false);

  if (
    !variables?.filters?.find((item) => item.value === "user authentication")
  ) {
    variables.filters.push({
      identifier: "excludeCategories",
      value: "user authentication",
    });
  }

  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useQuery(ACTIVITIES_QUERY, {
    variables,
  });

  const Facets = FacetsList([OverridableListFacet, OverridableDateRangeFacet]);
  let modifiedData = { ...data };

  useEffect(() => {
    if (!useSaved) {
      dispatch(saveVariables(variables));
    }
  }, [dispatch, useSaved, variables]);

  useEffect(() => {
    if (data?.activities?.results && useSaved) {
      api.setQuery(savedVariables?.query);
      savedVariables?.filters.forEach((filter) => api.addFilter(filter));
      api.setSortBy(savedVariables?.sortBy);
      api.setPage(savedVariables?.page);
      setUseSaved(false);
      setDefaultDate(true);
      api.search();
    }
  }, [
    api,
    data,
    savedVariables?.filters,
    savedVariables?.page,
    savedVariables?.query,
    savedVariables?.sortBy,
    useSaved,
  ]);

  useEffect(() => {
    return () => {
      dispatch(persistVariables());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDefaultFilter = () => {
    const defaultRange = getSpecificDateRange(undefined, "eventTime");
    api.toggleFilter(defaultRange);
    api.search();
  };

  useEffect(() => {
    if (
      data?.activities?.results?.summary?.appliedFilters &&
      !dateSet &&
      !useSaved
    ) {
      const dateFilters = api.getFiltersByIdentifier("eventTime");
      if (dateFilters.length === 0) {
        toggleDefaultFilter();
      }
      setDefaultDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    api,
    data?.activities?.results?.summary?.appliedFilters,
    dateSet,
    useSaved,
  ]);

  if (error) {
    const errors = error.networkError?.result?.errors;
    if (errors?.length) {
      errors.forEach((error) => {
        const code = error.extensions.code;
        if (code) {
          window.location.href = redirectUrl;
        }
      });
    }
  }

  if (data?.activities?.results) {
    const entries = data?.activities?.results?.facets
      ?.find((facet) => facet.identifier === "eventType")
      ?.entries.map((entry) => ({
        ...entry,
        overrideLabel: entry.label
          .replace(/_/g, " ")
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" "),
      }));

    modifiedData = {
      ...data,
      activities: {
        ...data?.activities,
        results: {
          ...data?.activities?.results,
          summary: {
            ...data?.activities?.results?.summary,
            appliedFilters: [
              ...(
                data?.activities?.results?.summary?.appliedFilters || []
              ).filter(
                (filter) =>
                  filter.identifier !== "excludeCategories" &&
                  filter.identifier !== "eventOrigin" &&
                  filter.identifier !== "eventType"
              ),
              ...(data?.activities?.results?.summary?.appliedFilters || [])
                .filter((filter) => filter.identifier === "eventOrigin")
                .map((filter) => {
                  return {
                    ...filter,
                    overrideValue: data?.activities?.results?.facets
                      .find((facet) => facet.identifier === "eventOrigin")
                      ?.entries?.find((entry) => entry.value === filter.value)
                      ?.label,
                    display: "OverriddenListFilter",
                  };
                }),
              ...(data?.activities?.results?.summary?.appliedFilters || [])
                .filter((filter) => filter.identifier === "eventType")
                .map((filter) => {
                  return {
                    ...filter,
                    overrideValue: filter.value
                      .replace(/_/g, " ")
                      .toLowerCase()
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(" "),
                    display: "OverriddenListFilter",
                  };
                }),
            ],
          },
          facets: [
            {
              ...data?.activities?.results?.facets.find(
                (facet) => facet.identifier === "eventOrigin"
              ),
              display: "OverridableListFacet",
              label: "Filter By Initiator",
            },
            {
              ...data?.activities?.results?.facets.find(
                (facet) => facet.identifier === "eventTime"
              ),
              display: "OverridableDateRangeFacet",
              label: "Filter By Activity Date",
            },
            {
              ...data?.activities?.results?.facets.find(
                (facet) => facet.identifier === "eventType"
              ),
              display: "OverridableListFacet",
              entries,
              label: "Filter By Event Type",
            },
          ],
        },
      },
    };
  }

  let activityList = [];
  if (
    data?.activities?.results?.hits?.items &&
    data?.activities?.results?.hits?.items.length
  ) {
    activityList = data?.activities?.results?.hits?.items.map((activity) => {
      if (isComment(activity?.fields)) {
        return (
          <ListItem key={activity.id}>
            <ListItemAvatar>
              <Avatar
                sx={
                  parseInt(activity?.fields?.userId) === selfId
                    ? {
                        color: "#fff !important",
                        backgroundColor: `${indigo["A700"]} !important`,
                      }
                    : {}
                }
              >
                {`${activity.fields?.user?.firstName} ${activity.fields?.user?.lastName}`
                  .match(/\b\w/g)
                  .join("")
                  .toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <Grid container>
              <Grid item xs={12}>
                <Typography>{`"${activity?.fields?.description}"`}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {`${
                    parseInt(activity?.fields?.userId) === selfId
                      ? "You"
                      : `${activity.fields?.user?.firstName} ${activity.fields?.user?.lastName}`
                  } 
                                        said on ${format(
                                          new Date(activity?.fields?.eventTime),
                                          "MMM d, y h:mm a"
                                        )}`}
                  {activity?.fields?.objectItem ? " on " : ""}
                  {activity?.fields?.objectItem ? (
                    <Link
                      to={`${getReferralUrl(activity?.fields?.objectItem)}`}
                    >
                      {activity?.fields?.objectItem.name || ""}
                    </Link>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        );
      }
      return (
        <React.Fragment key={`fragment_${activity.id}`}>
          <ListItem key={activity.id}>
            <ListItemAvatar>
              <Avatar
                sx={
                  parseInt(activity?.fields?.userId) === selfId
                    ? {
                        color: "#fff !important",
                        backgroundColor: `${indigo["A700"]} !important`,
                      }
                    : {}
                }
              >
                {`${activity?.fields?.user?.firstName} ${activity?.fields?.user?.lastName}`
                  .match(/\b\w/g)
                  .join("")
                  .toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <Grid container>
              <Grid item xs={12}>
                {Boolean(activity?.fields?.objectItem) && (
                  <Typography>
                    {`${
                      parseInt(activity?.fields?.userId) === selfId
                        ? "You"
                        : `${activity?.fields?.user?.firstName} ${activity?.fields?.user?.lastName}`
                    } ${getCustomizedSummary(activity)}`}{" "}
                    {(activity?.fields?.objectItem?.typeName ===
                      ACTIVITY_TYPE_NAME.DOCSERVER_DOCUMENT ||
                      activity?.fields?.objectItem?.typeName ===
                        ACTIVITY_TYPE_NAME.RECEIVED_FAX_DOCUMENT) && (
                      <Link
                        to={`${getDocumentUrl(
                          activity?.fields?.objectItem,
                          activity?.fields?.clientId
                        )}`}
                        target="_blank"
                        rel="opener"
                      >
                        {activity?.fields?.objectItem?.name || ""}
                      </Link>
                    )}
                    {activity?.fields?.objectItem?.typeName ===
                      ACTIVITY_TYPE_NAME.SERVICE_REQUEST && (
                      <Link
                        to={`/service-requests/${activity?.fields?.clientId}/${activity?.fields?.objectItem?.key}`}
                        target="_blank"
                        rel="opener"
                      >
                        {activity?.fields?.objectItem.name || ""}
                      </Link>
                    )}
                    {(activity?.fields?.objectItem?.typeName ===
                      ACTIVITY_TYPE_NAME.SENTREFERRAL ||
                      activity?.fields?.objectItem?.typeName ===
                        ACTIVITY_TYPE_NAME.RCVDREFERRAL) && (
                      <Link
                        to={`${getReferralUrl(activity?.fields?.objectItem)}`}
                      >
                        {activity?.fields?.objectItem.name || ""}{" "}
                      </Link>
                    )}
                    {activity?.fields?.relatedItems &&
                    activity?.fields?.relatedItems.length > 0
                      ? getRelatedReferral(
                          activity?.fields?.relatedItems,
                          activity?.fields?.clientId
                        )
                      : null}
                  </Typography>
                )}
                {!activity?.fields?.objectItem && (
                  <Typography>{`${
                    parseInt(activity?.fields?.userId) === selfId
                      ? "You"
                      : `${activity?.fields?.user?.firstName} ${activity?.fields?.user?.lastName}`
                  } ${activity?.fields?.summary.toLowerCase()}`}</Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: "500",
                    lineHeight: "1.57",
                    letterSpacing: "0.00714em",
                  }}
                  variant="subtitle2"
                >
                  {getPatientName(activity?.fields)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: "500",
                    lineHeight: "1.57",
                    letterSpacing: "0.00714em",
                  }}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {format(
                    new Date(activity?.fields?.eventTime),
                    "MMM d, y h:mm a"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          {activity?.fields?.changedValues && (
            <List
              key={`changelist_${activity.id}`}
              component="div"
              disablePadding
            >
              {" "}
              {activity?.fields?.changedValues.map((change) => {
                const displayOptions = isValidToDisplay(change.fieldName);
                if (displayOptions) {
                  const displayFrom = displayOptions.displayFn
                    ? displayOptions.displayFn(change.changedFrom)
                    : change.changedFrom;
                  const displayTo = displayOptions.displayFn
                    ? displayOptions.displayFn(change.changedTo)
                    : change.changedTo;
                  if (
                    (!displayFrom || displayFrom === "") &&
                    (!displayTo || displayTo === "")
                  ) {
                    return null;
                  }
                  return (
                    <ListItem
                      id={`${activity.id}_change_${change.fieldName}`}
                      key={change.fieldName}
                    >
                      {displayFrom &&
                        displayFrom !== "" &&
                        displayTo &&
                        displayTo !== "" && (
                          <ListItemText
                            primary={`${displayOptions.displayName} changed from "${displayFrom}" to "${displayTo}".`}
                          ></ListItemText>
                        )}
                      {displayFrom &&
                        displayFrom !== "" &&
                        (!displayTo || displayTo === "") && (
                          <ListItemText
                            primary={`${displayOptions.displayName} "${displayFrom}" was removed.`}
                          ></ListItemText>
                        )}
                      {(!displayFrom || displayFrom === "") &&
                        displayTo &&
                        displayTo !== "" && (
                          <ListItemText
                            primary={`${displayOptions.displayName} was set to "${displayTo}".`}
                          ></ListItemText>
                        )}
                    </ListItem>
                  );
                }
                return null;
              })}
            </List>
          )}
        </React.Fragment>
      );
    });
  }

  return (
    <div
      style={{
        width: "70%",
        // eslint-disable-next-line
        ...(format == "full" && { marginLeft: 24, marginRight: 24 }),
        // eslint-disable-next-line
        ...(format == "compact" && {
          width: "90%",
          marginTop: 24,
          marginBottom: 24,
        }),
        ...(newForm && {
          width: "calc(100% - 24px)",
          margin: "24px 8px 24px 16px",
        }),
      }}
    >
      <Tooltip title={"List of specified actions that have occurred recently"}>
        <div
          style={{
            color: "#ffffff",
            backgroundColor: "#516794",
            flexBasis: "45%",
            flexShrink: 0,
            padding: "2px 0px 2px 5px",
            borderRadius: 2,
          }}
        >
          {"ACTIVITY STREAM"}
        </div>
      </Tooltip>
      <Box
        id="page-activity-stream"
        sx={{
          padding: "16px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            minWidth: "192px",
            marginRight: "16px",
            flex: "0 1",
          }}
        >
          <hr
            style={{
              border: "none",
              height: "1px",
              backgroundColor: "#d3dae6",
              width: "100%",
              margin: "16px 0",
            }}
          />
          <Facets data={modifiedData?.activities?.results} loading={loading} />
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              marginBottom: "16px",
            }}
          >
            <PHSelectedFilters
              data={modifiedData?.activities?.results}
              loading={loading}
              customFilterComponents={{
                OverriddenListFilter: OverridableValueFilter,
              }}
            />
          </Box>
          <Box
            id="activity-results"
            style={{
              backgroundColor: "#fff",
              borderRadius: "6px",
              boxShadow:
                "0 0.9px 4px -1px rgba(0,0,0,.08), 0 2.6px 8px -1px rgba(0,0,0,.06), 0 5.7px 12px -1px rgba(0,0,0,.05), 0 15px 15px -1px rgba(0,0,0,.04)",
              padding: "24px",
              flexGrow: "1",
              height: "100%",
            }}
          >
            <List key="activityList">{activityList}</List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination data={data?.activities?.results} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default withSearchkit(withSearchkitRouting(HomeActivityStream));

HomeActivityStream.propTypes = {
  selfId: PropTypes.number,
  newForm: PropTypes.any,
};
