import { format, isValid } from "date-fns";
import { ACTIVITY_EVENT_TYPE, NOTIFICATION_TYPE } from "../constants";

export function isComment(activity) {
  return activity.summary === "Added a comment";
}

export function isValidToDisplay(fieldName) {
  const validChangeFields = [
    {
      name: "patient",
      displayName: "Patient",
      displayFn: (patient) => {
        try {
          const jsonPatient = JSON.parse(patient);
          if (jsonPatient) {
            return `${jsonPatient.firstName} ${jsonPatient.lastName}`;
          }
        } catch (e) {
          // no-op
        }
      },
    },
    {
      name: "recipientPractice",
      displayName: "Refer To Practice",
      displayFn: (recipientPractice) => {
        try {
          const jsonRecipientPractice = JSON.parse(recipientPractice);
          if (jsonRecipientPractice) {
            return `${jsonRecipientPractice.orgName}`;
          }
        } catch (e) {
          // no-op
        }
      },
    },
    {
      name: "recipient",
      displayName: "Refer To Practice",
      displayFn: (recipientPractice) => {
        try {
          const jsonRecipientPractice = JSON.parse(recipientPractice);
          if (jsonRecipientPractice) {
            return `${jsonRecipientPractice.orgName}`;
          }
        } catch (e) {
          // no-op
        }
      },
    },
    {
      name: "description",
      displayName: "Description",
    },
    {
      name: "specialty",
      displayName: "Specialty",
    },
    {
      name: "referralRecipientPhysician",
      displayName: "Preferred Provider",
    },
    {
      name: "referralRecipientPhysicianActual",
      displayName: "Seen By",
    },
    {
      name: "senderPractice",
      displayName: "Referring Practice",
    },
    {
      name: "senderName",
      displayName: "Referring Physician",
    },
    {
      name: "consultDate",
      displayName: "Consult Date",
      displayFn: (consultDate) => {
        if (isValid(new Date(consultDate))) {
          return format(new Date(consultDate), "MM/dd/yyyy");
        }
      },
    },
    {
      name: "oonReason",
      displayName: "Out of Network Reason",
    },
    {
      name: "priority",
      displayName: "Priority",
    },
  ];
  return validChangeFields.find((name) => name.name === fieldName);
}

export function getReferralUrl(ref) {
  return `/referrals/${
    ref.typeName.toLowerCase().startsWith("se") ? "sent" : "received"
  }/${ref.key}`;
}

export function getDocumentUrl(doc, clientId) {
  return `/referral-documents/${clientId}/${doc.key}`;
}

export function getPatientName({ objectItem }) {
  if (objectItem?.typeName === "PATIENT_RECORD") {
    return objectItem?.name;
  }
  return "";
}

function getNotificationType(notificatinType) {
  switch (notificatinType) {
    case NOTIFICATION_TYPE.REFERRAL_FLAGGED:
      return "flagged";
    case NOTIFICATION_TYPE.REFERRAL_UNFLAGGED:
      return "unflagged";
    case NOTIFICATION_TYPE.DOCUMENT_ADDED_TO_PROCESS:
      return "document attached";
    case NOTIFICATION_TYPE.ADD_COMMENT:
      return "comments";
    default:
      return "";
  }
}

export function getCustomizedSummary(activity) {
  if (activity?.fields?.eventType === ACTIVITY_EVENT_TYPE.NOTIFICATION_READ) {
    return `marked the ${getNotificationType(
      activity?.fields?.objectItem?.name
    )} notification as read`;
  }
  if (activity?.fields?.eventType === ACTIVITY_EVENT_TYPE.NOTIFICATION_UNREAD) {
    return `marked the ${getNotificationType(
      activity?.fields?.objectItem?.name
    )} notification as unread`;
  }
  return activity?.fields?.summary.toLowerCase();
}
